import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { container4 } from "../../../constants/classnames";
import { CasesUT } from "./Cases/CasesUT";
import { BciApplicationDateModal } from "./bciApplicationDateModal";
import { ProcessExplanationUT } from "./Process/ProcessExplanationUT";
import { Checklist } from "./Checklist";
import { getAnswersAll, getStatus } from "../../../api/secureApi";
import { UT } from "../../../constants/usStates";
import { setUpAZ, setUpUT } from "./Cases/utils";
import { CasesAZ } from "./Cases/CasesAZ";
import { initMattersAZ } from "./Cases/constants";
import { ProcessAZ } from "./Process/ProcessAZ";

const tabStart =
  "rounded-t-lg border-t border-x border-pink-100 bg-rasapink p-4 leading-4 text-gray-100 tracking-tighter focus:outline-none";
const activeTabStart =
  "rounded-t-lg border border-white bg-white p-4 leading-4 text-black tracking-tighter font-semibold focus:outline-none";
const tab = "-ml-1 " + tabStart;
const activeTab = "-ml-1 " + activeTabStart;
const yourChecklist = "your checklist";
const yourCases = "your cases";
const theProcess = "the process";

export function MatterStatus() {
  const navigate = useNavigate();
  const us_state = localStorage.getItem("us_state");
  const [isLoading, setIsLoading] = useState(true);
  const [openApplicationSubmitted, setOpenApplicationSubmitted] = useState(false);
  const [bciSubmitted, setBciSubmitted] = useState(null);
  const [showBciDateNeeded, setShowBciDateNeeded] = useState(true);
  const [oneExpungementInBciApplicationStage, setOneExpungementInBciApplicationStage] =
    useState(false);
  const [psSurveyDone, setPsSurveyDone] = useState(false);
  const [showPsNeeded, setShowPsNeeded] = useState(us_state === UT ? true : false);
  const [oneExpungementInPersonalStatementStage, setOneExpungementInPersonalStatementStage] =
    useState(false);
  const [paymentNeeded, setPaymentNeeded] = useState(false);
  const [mattersExpungement, setMattersExpungement] = useState([]);
  const [mattersReduction, setMattersReduction] = useState([]);
  const [mattersOther, setMattersOther] = useState([]);
  const [mattersAZ, setMattersAZ] = useState(initMattersAZ);
  const [selectedTab, setSelectedTab] = useState(yourCases);

  useEffect(() => {
    if (!psSurveyDone) {
      // only reason to call this is to set psSurveyDone to true
      getAnswersAll()
        .then((response) => {
          const psAnswersCount = response.length;
          if (psAnswersCount > 0) {
            setPsSurveyDone(true);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("error getting all answers", error);
        });
    }
  }, [psSurveyDone]);

  useEffect(() => {
    if (us_state === UT) {
      setShowPsNeeded(
        !psSurveyDone &&
          (mattersExpungement.length > 0 || mattersReduction.length > 0) &&
          (oneExpungementInBciApplicationStage || oneExpungementInPersonalStatementStage),
      );
    }
  }, [
    us_state,
    psSurveyDone,
    mattersExpungement,
    mattersReduction,
    oneExpungementInBciApplicationStage,
    oneExpungementInPersonalStatementStage,
  ]);

  useEffect(() => {
    getStatus()
      .then(async (response) => {
        // eslint-disable-next-line no-console
        console.log("response", response);
        const state_specific_data = response?.state_specific_data;
        const personal_statement_drafted = response?.personal_statement_drafted;
        if (personal_statement_drafted) {
          setPsSurveyDone(true);
        }
        const matters = response?.matters;
        // eslint-disable-next-line no-console
        console.log("matters", matters);
        if (us_state === UT) {
          setUpUT(
            matters,
            state_specific_data,
            setBciSubmitted,
            setPaymentNeeded,
            showPsNeeded,
            showBciDateNeeded,
            setOneExpungementInBciApplicationStage,
            setOneExpungementInPersonalStatementStage,
            setMattersExpungement,
            setMattersReduction,
            setMattersOther,
            setShowBciDateNeeded,
            oneExpungementInBciApplicationStage,
          );
        } else {
          setShowBciDateNeeded(false);
          setUpAZ(
            matters,
            state_specific_data,
            setPaymentNeeded,
            showPsNeeded,
            setShowPsNeeded,
            setMattersAZ,
          );
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === 401) {
          navigate("/login");
        }
      });
  }, [
    us_state,
    navigate,
    oneExpungementInBciApplicationStage,
    setOneExpungementInBciApplicationStage,
    oneExpungementInPersonalStatementStage,
    setOneExpungementInPersonalStatementStage,
    showBciDateNeeded,
    showPsNeeded,
    setMattersExpungement,
    setMattersReduction,
    setMattersOther,
  ]);

  return (
    <div className={container4}>
      <div className="mx-auto w-full max-w-xl rounded border border-rasapink">
        <div className="rounded-t bg-rasapink pt-4">
          <div className="flex">
            <button
              className={selectedTab === yourChecklist ? activeTabStart : tabStart}
              onClick={() => setSelectedTab(yourChecklist)}
            >
              {yourChecklist}
            </button>
            <button
              className={selectedTab === yourCases ? activeTab : tab}
              onClick={() => setSelectedTab(yourCases)}
            >
              {yourCases}
            </button>
            <button
              className={selectedTab === theProcess ? activeTab : tab}
              onClick={() => setSelectedTab(theProcess)}
            >
              {theProcess}
            </button>
          </div>
        </div>
        {selectedTab === yourChecklist && (
          <div id={yourChecklist} className="rounded-lg bg-white p-4 shadow-md">
            <Checklist
              showBciDateNeeded={showBciDateNeeded}
              showPsNeeded={showPsNeeded}
              paymentNeeded={paymentNeeded}
              setOpenApplicationSubmitted={setOpenApplicationSubmitted}
              navigate={navigate}
              mattersExpungement={mattersExpungement}
            />
          </div>
        )}
        {selectedTab === yourCases && (
          <div id={yourCases} className="rounded-lg bg-white p-3 shadow-md">
            <div>
              We want to make sure you know where your cases are in the process at all times. The
              timeline below will show you where each of your cases is at this time.
            </div>
            {us_state === UT ? (
              <CasesUT
                navigate={navigate}
                isLoading={isLoading}
                bciSubmitted={bciSubmitted}
                psSurveyDone={psSurveyDone}
                mattersExpungement={mattersExpungement}
                mattersReduction={mattersReduction}
                mattersOther={mattersOther}
                setBciSubmitted={setBciSubmitted}
              />
            ) : (
              <CasesAZ
                navigate={navigate}
                isLoading={isLoading}
                psSurveyDone={psSurveyDone}
                matters={mattersAZ}
              />
            )}
          </div>
        )}
        {selectedTab === theProcess && (
          <div id={theProcess} className="rounded-lg bg-white p-4 shadow-md">
            <div className="mb-4">Click the down arrows next to each line to learn more.</div>
            {us_state === UT ? (
              <ProcessExplanationUT
                setOpenApplicationSubmitted={setOpenApplicationSubmitted}
                bciSubmitted={bciSubmitted}
                showBciDateNeeded={showBciDateNeeded}
                psSurveyDone={psSurveyDone}
                isPersonalStatementNeeded={showPsNeeded}
                mattersReduction={mattersReduction}
                mattersOther={mattersOther}
                mattersExpungement={mattersExpungement}
              />
            ) : (
              <ProcessAZ
                setOpenApplicationSubmitted={setOpenApplicationSubmitted}
                bciSubmitted={bciSubmitted}
                showBciDateNeeded={showBciDateNeeded}
                psSurveyDone={psSurveyDone}
                isPersonalStatementNeeded={showPsNeeded}
                mattersReduction={mattersReduction}
                mattersOther={mattersOther}
                mattersExpungement={mattersExpungement}
              />
            )}
          </div>
        )}
      </div>
      <BciApplicationDateModal
        open={openApplicationSubmitted}
        setOpen={setOpenApplicationSubmitted}
        isCurrent={false}
        setBciSubmitted={setBciSubmitted}
      />
    </div>
  );
}
