import { ProgressStepperReduction } from "../ProgressStepper/ProgressStepperReduction";
import { severityMapping } from "./CasesUT";

export const CasesReductions = ({ mattersReduction, openReductionModalWithStatus }) => {
  return (
    <>
      {mattersReduction.map((m, index) => {
        return (
          <div key={`m.case_number-${index}-r`} className="mt-10">
            <div className="text-sm">
              <div className="font-bold leading-4">
                {index + 1}. {m.lead_charge_description}
              </div>
              <div>{severityMapping[m.lead_charge_severity] || m.lead_charge_severity}</div>
              <div>Case Number: {m.case_number}</div>
              {m.case_year && <div>Case Year: {m.case_year}</div>}
              <div>{m.court}</div>
              <div>{m.is_two_step ? "Two Step Reduction" : "One Step Reduction"}</div>
              <button
                className="w-fit font-bold text-rasapink underline"
                onClick={() => openReductionModalWithStatus(m.status, m.docs_filed)}
              >
                Status: {m.status}
              </button>
            </div>
            <ProgressStepperReduction currentStep={m.status} />
          </div>
        );
      })}
    </>
  );
};
