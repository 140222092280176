import { ProgressStepperExpungement } from "../ProgressStepper/ProgressStepperExpungement";
import { useEffect, useState } from "react";
import { StatusModal } from "../StatusModal";
import { bciApplication, getExpungementDescription } from "../Descriptions/DescriptionsExpungement";
import { BciApplicationDateModal } from "../bciApplicationDateModal";
import { getReductionDescription } from "../Descriptions/DescriptionsReduction";
import { ShowContent } from "../ShowContent";
import { CasesReductions } from "./CasesReductions";
import { loading } from "../../../../components/loading";
import { CasesOtherMotions } from "./CasesOtherMotions";
import { getOtherMotionDescription } from "../Descriptions/DescriptionsOtherMotions";

export const severityMapping = {
  F1: "First-Degree Felony",
  F2: "Second-Degree Felony",
  F3: "Third-Degree Felony",
  MA: "Class A Misdemeanor",
  MB: "Class B Misdemeanor",
  MC: "Class C Misdemeanor",
  IN: "Infraction",
  NA: "",
};

const cases = "my-6 rounded border border-grey-500 px-2 py-1";
const header = "-mx-2 -my-1 flex justify-between rounded bg-gray-200 px-2 py-1";

export const CasesUT = ({
  navigate,
  isLoading,
  bciSubmitted,
  psSurveyDone,
  mattersExpungement,
  mattersReduction,
  mattersOther,
  setBciSubmitted,
}) => {
  const [open, setOpen] = useState(false);
  const [openApplicationSubmitted, setOpenApplicationSubmitted] = useState(false);
  const [statusTitle, setStatusTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isShowingReduction, setIsShowingReduction] = useState(false);
  const [isShowingOther, setIsShowingOther] = useState(false);

  useEffect(() => {
    if (mattersExpungement.length === 0) {
      if (mattersReduction.length > 0) {
        setIsShowingReduction(true);
      } else if (mattersOther.length > 0) {
        setIsShowingOther(true);
      }
    }
  }, [mattersExpungement, mattersReduction, mattersOther]);

  const openModalWithStatus = (statusParam, filedDate) => {
    setStatusTitle(statusParam);
    if (statusParam === bciApplication) {
      setOpenApplicationSubmitted(true);
    } else {
      setDescription(
        getExpungementDescription(statusParam, navigate, bciSubmitted, psSurveyDone, filedDate),
      );
      setOpen(true);
    }
  };

  const openReductionModalWithStatus = (statusParam, filedDate) => {
    setStatusTitle(statusParam);
    setDescription(
      getReductionDescription(statusParam, navigate, psSurveyDone, false, false, filedDate),
    );
    setOpen(true);
  };

  const openOtherModalWithStatus = (statusParam, filedDate) => {
    setStatusTitle(statusParam);
    setDescription(getOtherMotionDescription(statusParam, filedDate));
    setOpen(true);
  };

  return (
    <div>
      {mattersOther?.length ? (
        <div className={cases}>
          <div className={header}>
            <div className="font-bold">your other motions ({mattersOther.length})</div>
            <ShowContent isShowing={isShowingOther} setIsShowing={setIsShowingOther} />
          </div>
          {isShowingOther && (
            <CasesOtherMotions
              matters={mattersOther}
              openModalWithStatus={openOtherModalWithStatus}
            />
          )}
        </div>
      ) : null}
      {mattersReduction?.length ? (
        <div className={cases}>
          <div className={header}>
            <div className="font-bold">your reductions ({mattersReduction.length})</div>
            <ShowContent isShowing={isShowingReduction} setIsShowing={setIsShowingReduction} />
          </div>
          {isShowingReduction && (
            <CasesReductions
              mattersReduction={mattersReduction}
              openReductionModalWithStatus={openReductionModalWithStatus}
            />
          )}
        </div>
      ) : null}
      {mattersExpungement?.length ? (
        <div className={cases}>
          <div className={header}>
            <div className="font-bold">your expungements ({mattersExpungement.length})</div>
          </div>
          {isLoading ? (
            <div className="mt-8 text-center">{loading}</div>
          ) : (
            <>
              {mattersExpungement.map((m, index) => {
                return (
                  <div key={`m.case_number-${index}-e`} className="mt-10">
                    <div className="text-sm">
                      <div className="font-bold leading-4">
                        {index + 1}. {m.lead_charge_description}
                      </div>
                      <div>{severityMapping[m.lead_charge_severity] || m.lead_charge_severity}</div>
                      <div>Case Number: {m.case_number}</div>
                      {m.case_year && <div>Case Year: {m.case_year}</div>}
                      <div>{m.court}</div>
                      <button
                        className="w-fit font-bold text-rasapink underline"
                        onClick={() => openModalWithStatus(m.status, m.docs_filed)}
                      >
                        Status: {m.status}
                      </button>
                    </div>
                    <ProgressStepperExpungement currentStep={m.status} />
                  </div>
                );
              })}
            </>
          )}
        </div>
      ) : null}
      <StatusModal open={open} setOpen={setOpen} title={statusTitle} description={description} />
      <BciApplicationDateModal
        open={openApplicationSubmitted}
        setOpen={setOpenApplicationSubmitted}
        isCurrent={true}
        setBciSubmitted={setBciSubmitted}
      />
    </div>
  );
};
