import { useState } from "react";
import { ProcessReduction } from "./ProcessReduction";
import { ProcessExpungement } from "./ProcessExpungement";
import { ShowContent } from "../ShowContent";
import { ProcessOther } from "./ProcessOther";
const process = "mt-2 mb-4 rounded border border-grey-500 px-2 py-1";
const header = "-mx-2 -my-1 flex justify-between rounded bg-gray-200 px-2 py-1";
export const ProcessExplanationUT = ({
  setOpenApplicationSubmitted,
  bciSubmitted,
  showBciDateNeeded,
  psSurveyDone,
  isPersonalStatementNeeded,
  mattersReduction,
  mattersOther,
}) => {
  const [isShowingExpungement, setIsShowingExpungement] = useState(true);
  const [isShowingReduction, setIsShowingReduction] = useState(false);
  const [isShowingOther, setIsShowingOther] = useState(false);

  return (
    <>
      {!!mattersOther.length && (
        <div className={process}>
          <div className={header}>
            <div className="font-bold">other motions process</div>
            <ShowContent isShowing={isShowingOther} setIsShowing={setIsShowingOther} />
          </div>
          {isShowingOther && <ProcessOther psSurveyDone={psSurveyDone} />}
        </div>
      )}
      {!!mattersReduction.length && (
        <div className={process}>
          <div className={header}>
            <div className="font-bold">reduction process</div>
            <ShowContent isShowing={isShowingReduction} setIsShowing={setIsShowingReduction} />
          </div>
          {isShowingReduction && <ProcessReduction psSurveyDone={psSurveyDone} />}
        </div>
      )}
      <div className={process}>
        <div className={header}>
          <div className="font-bold">expungement process</div>
          <ShowContent isShowing={isShowingExpungement} setIsShowing={setIsShowingExpungement} />
        </div>
        {isShowingExpungement && (
          <ProcessExpungement
            setOpenApplicationSubmitted={setOpenApplicationSubmitted}
            bciSubmitted={bciSubmitted}
            showBciDateNeeded={showBciDateNeeded}
            psSurveyDone={psSurveyDone}
            isPersonalStatementNeeded={isPersonalStatementNeeded}
          />
        )}
      </div>
    </>
  );
};
