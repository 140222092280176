import { useState } from "react";
import { StatusModal } from "../StatusModal";
import { loading } from "../../../../components/loading";
import { ProgressStepperAZ } from "../ProgressStepper/ProgressStepperAZ";
import { getDescriptionAZ } from "../Descriptions/DescriptionsAZ";

const severityMapping = (code) => {
  code = code?.trim();

  const mapping = {
    F1: "Class 1 Felony",
    "FELONY 1ST DEGREE": "Class 1 Felony",
    F2: "Class 2 Felony",
    "FELONY 2ND DEGREE": "Class 2 Felony",
    "FELONY 2ST DEGREE": "Class 2 Felony",
    F3: "Class 3 Felony",
    "FELONY 3RD DEGREE": "Class 3 Felony",
    "FELONY 3ST DEGREE": "Class 3 Felony",
    F4: "Class 4 Felony",
    "FELONY 4ST DEGREE": "Class 4 Felony",
    "FELONY 4TH DEGREE": "Class 4 Felony",
    F5: "Class 5 Felony",
    "FELONY 5ST DEGREE": "Class 5 Felony",
    "FELONY 5TH DEGREE": "Class 5 Felony",
    F6: "Class 6 Felony",
    "FELONY 6ST DEGREE": "Class 6 Felony",
    "FELONY 6TH DEGREE": "Class 6 Felony",
    M1: "Class 1 Misdemeanor",
    "MISDEMEANOR 1ST DEGREE": "Class 1 Misdemeanor",
    M2: "Class 2 Misdemeanor",
    "MISDEMEANOR 2ND DEGREE": "Class 2 Misdemeanor",
    "MISDEMEANOR 2ST DEGREE": "Class 2 Misdemeanor",
    M3: "Class 3 Misdemeanor",
    "MISDEMEANOR 3RD DEGREE": "Class 3 Misdemeanor",
    "MISDEMEANOR 3ST DEGREE": "Class 3 Misdemeanor",
    M4: "Class 4 Misdemeanor",
    M5: "Class 5 Misdemeanor",
    M6: "Class 6 Misdemeanor",
    "NON - CRIMINAL": "non-criminal",
    "PETTY OFFENSE": "",
    TRAFFIC: "",
  };

  return mapping[code] || code;
};

const formatRemedy = (r) => {
  return r.replaceAll("_", " ");
};

const cases = "my-6 rounded border border-grey-500 px-2 py-1";
const header = "-mx-2 -my-1 flex justify-between rounded bg-gray-200 px-2 py-1";

export const CasesAZ = ({ navigate, isLoading, psSurveyDone, matters }) => {
  const [open, setOpen] = useState(false);
  const [statusTitle, setStatusTitle] = useState("");
  const [description, setDescription] = useState("");

  const openModalWithStatus = (statusParam, filedDate) => {
    setStatusTitle(statusParam);
    setDescription(getDescriptionAZ(statusParam, navigate, psSurveyDone, filedDate));
    setOpen(true);
  };

  return (
    <div className={cases}>
      {Object.keys(matters).map((k) => {
        if (matters[k].length) {
          return (
            <div key={`wrapper-${k}-e`} className={cases}>
              <div className={header}>
                <div className="font-bold">
                  your {formatRemedy(k)} ({matters[k].length})
                </div>
              </div>
              {isLoading ? (
                <div className="mt-8 text-center">{loading}</div>
              ) : (
                <>
                  {matters[k].map((m, index) => {
                    return (
                      <div key={`m.case_number-${index}-e`} className="mt-10">
                        <div className="text-sm">
                          <div className="font-bold leading-4">
                            {index + 1}. {m.lead_charge_description || m.description}
                          </div>
                          <div>
                            {severityMapping(m.lead_charge_severity) || m.lead_charge_severity}
                          </div>
                          <div>Case Number: {m.case_number}</div>
                          {m.case_year && <div>Case Year: {m.case_year}</div>}
                          <div>{m.court}</div>
                          <button
                            className="w-fit font-bold text-rasapink underline"
                            onClick={() => openModalWithStatus(m.status, m.docs_filed)}
                          >
                            Status: {m.status}
                          </button>
                        </div>
                        <ProgressStepperAZ currentStep={m.status} />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        }
      })}
      <StatusModal open={open} setOpen={setOpen} title={statusTitle} description={description} />
    </div>
  );
};
