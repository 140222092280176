import { Link } from "react-router-dom";

export const LoginForgotButtons = ({ email = "", loginClass, forgotClass }) => {
  return (
    <>
      <Link to="/login" state={{ email: email }}>
        <button type="button" className={loginClass}>
          yes, <span className="tracking-tighter">take me to login</span>
        </button>
      </Link>
      <Link to="/forgot" state={{ email: email }}>
        <button type="button" className={forgotClass}>
          yes, <span className="tracking-tighter">but I forgot my password</span>
        </button>
      </Link>
    </>
  );
};
