import { useState } from "react";
import { Complete, Court, Drafting, PersonalInfo } from "../icons";
import { ShowContent } from "../ShowContent";
import {
  complete,
  draftingFiling,
  waitingForCourt,
  yourPersonalInfo,
} from "../Descriptions/DescriptionsExpungement";
import { STATIC } from "../ProgressStepper/ProgressStepperExpungement";
import { actionEntity, content, LI, row } from "./ProcessExpungement";
import { useNavigate } from "react-router-dom";
import {
  getCompleteDescriptionAZ,
  getDraftingDescriptionAZ,
  getPersonalInfoDescriptionAZ,
  getWaitingForCourtDescriptionAZ,
} from "../Descriptions/DescriptionsAZ";

export const ProcessAZ = ({ psSurveyDone, isPersonalStatementNeeded }) => {
  const navigate = useNavigate();
  const [isShowingPersonalInfo, setIsPersonalInfo] = useState(false);
  const [isShowingDraft, setIsShowingDraft] = useState(false);
  const [isShowingCourt, setIsShowingCourt] = useState(false);
  const [isShowingComplete, setIsShowingComplete] = useState(false);

  return (
    <>
      <ol className="mt-3">
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <PersonalInfo stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {yourPersonalInfo} <div className={actionEntity}> (you)</div>
              </div>
              <ShowContent isShowing={isShowingPersonalInfo} setIsShowing={setIsPersonalInfo} />
            </div>
          </div>
          {isShowingPersonalInfo && (
            <div className={content}>
              {getPersonalInfoDescriptionAZ(isPersonalStatementNeeded, psSurveyDone, navigate)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Drafting stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {draftingFiling} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingDraft} setIsShowing={setIsShowingDraft} />
            </div>
          </div>
          {isShowingDraft && <div className={content}>{getDraftingDescriptionAZ(false)}</div>}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Court stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {waitingForCourt} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingCourt} setIsShowing={setIsShowingCourt} />
            </div>
          </div>
          {isShowingCourt && (
            <div className={content}>{getWaitingForCourtDescriptionAZ(false, false, false)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Complete stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {complete} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingComplete} setIsShowing={setIsShowingComplete} />
            </div>
          </div>
          {isShowingComplete && <div className={content}>{getCompleteDescriptionAZ(false)}</div>}
        </li>
      </ol>
    </>
  );
};
