import { Link } from "react-router-dom";
import {
  complete,
  draftingFiling,
  waitingForCourt,
  yourPersonalInfo,
} from "./DescriptionsExpungement";
import { denied } from "../Cases/mapWorkflowToStatusAZ";

export const getPersonalInfoDescriptionAZ = (isCurrent, psSurveyDone, navigate) => {
  if (!isCurrent) {
    return (
      <>
        <div>
          Here we gather information from you to tell the court how your criminal record has been a
          barrier and what positive changes you have made in your life since your time in the
          justice system. We use this information to draft your legal documents.
        </div>
      </>
    );
  } else if (psSurveyDone) {
    return (
      <>
        <div>
          Thank you! We have received the information we need to tell the court how your criminal
          record has been a barrier and what positive changes you have made in your life since your
          time in the justice system. We will use this information to draft your legal documents.
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        We need to gather some information from you, so we can prepare your court documents. More
        specifically, we need to tell the court how your criminal record has been a barrier and what
        positive changes you have made in your life since your time in the justice system.
      </div>
      <div className="mt-2">
        We collect this through a simple survey that should take you about 10 minutes to complete.{" "}
        <Link onClick={() => navigate("/statement")}>
          <span className="text-rasapink underline">Please fill it out here.</span>
        </Link>
      </div>
    </>
  );
};

export const getDraftingDescriptionAZ = (isCurrent) => {
  if (isCurrent) {
    return "We are currently drafting and filing your legal documents.";
  }
  return "During this phase, Rasa is drafting and filing your legal documents.";
};

export const getWaitingForCourtDescriptionAZ = (isCurrent, isHearing, isDenied) => {
  if (isDenied) {
    return (
      "The Court has denied the petition/motion on this matter." +
      " We will evaluate your case for the next best steps to move towards future expungement."
    );
  } else if (isHearing) {
    return (
      "The Court in this matter has set a hearing to hear argument about whether" +
      " this petition/motion should be granted. If you would still like" +
      " to move forward, we will invoice you $500 and will represent" +
      " you at this hearing. We will be in touch with more detail about this case."
    );
  } else if (isCurrent) {
    return (
      <div>
        <div>
          Good news! Your documents have been filed. We are now waiting for the court to make a
          final decision.
        </div>
        <div className="mt-2">
          Please note <b>it can take 4-6 months to hear back from the court.</b> Rasa has no way to
          speed up this process.
        </div>
      </div>
    );
  }
  return "At this point, your documents have been filed. We wait for the court to make a final decision.";
};

export const getCompleteDescriptionAZ = (isCurrent) => {
  if (isCurrent) {
    return (
      <div>
        Congratulations, everything is complete! Watch your email for copies of your petition/motion
        and court order.
      </div>
    );
  }
  return (
    "When everything is complete, Rasa will make all your documents" +
    " available for you to download and keep for your records."
  );
};

export const getDescriptionAZ = (
  status,
  navigate,
  psSurveyDone,
  // filedDate,
) => {
  switch (status) {
    case yourPersonalInfo:
      return getPersonalInfoDescriptionAZ(true, psSurveyDone, navigate);
    case draftingFiling:
      return getDraftingDescriptionAZ(true);
    case waitingForCourt:
      return getWaitingForCourtDescriptionAZ(true, false, false);
    case complete:
      return getCompleteDescriptionAZ(true);
    case denied:
      return "The Court has denied the petition/motion on this matter. We will evaluate your case for the next best steps to move towards future clearance.";
    default:
      return "The status of this case is not known. Please contact Rasa for more information.";
  }
};
