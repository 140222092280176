import { Link } from "react-router-dom";
import { ClioLink } from "../../Clio/ClioPage";
import { format, parseISO } from "date-fns";
import { formatShortString } from "../utilsUT";

export const bciApplication = "BCI application";
export const waitingForOtherWork = "not yet ready";
export const yourPersonalInfo = "personal statement questions";
export const waitingForBci = "waiting for BCI";
export const draftingFiling = "drafting & filing";
export const prosecutorReview = "prosecutor review";
export const prosecutorObjection = "prosecutor objection";
export const waitingForCourt = "waiting for court";
export const finalization = "finalization";
export const expungementComplete = "expungement complete!";

export const reductionComplete = "reduction complete!";

export const complete = "complete!";
export const motionComplete = "motion complete!";

// export const ineligiblePerBci = "ineligible by BCI";
export const caseWithdrawn = "case withdrawn or denied";

export const expungementDenied = "expungement denied";
export const reductionDenied = "denied or prosecutor won't agree";
export const motionDenied = "motion denied";
export const ineligible = "ineligible";
export const ineligibleMispelled = "ineligble";
export const clientWithdrew = "client withdrew from service";
export const paymentNeededStatus = "payment needed";

export const getBciApplicationDescription = (
  bciApplicationDate,
  showBciDateNeeded,
  setOpenApplicationSubmitted,
) => {
  if (bciApplicationDate) {
    return "You told us that you submitted your BCI Application on " + bciApplicationDate + ".";
  }
  if (!showBciDateNeeded) {
    return "To begin the expungement process, you must submit your BCI Application directly to BCI.";
  }
  return (
    <div>
      <div>
        We are currently waiting for YOU to submit your application to BCI (Utah Bureau of Criminal
        Identification).
      </div>
      <div>
        If you have already submitted your application, please{" "}
        <button
          type="button"
          className="text-rasapink underline"
          onClick={() => setOpenApplicationSubmitted(true)}
        >
          let us know
        </button>{" "}
        what date you submitted it, so we can record it here and track your application.
      </div>
    </div>
  );
};

export const getPersonalInfoDescription = (isCurrent, psSurveyDone, navigate) => {
  if (!isCurrent) {
    return (
      <>
        <div>
          Here we gather information from you to tell the court how your criminal record has been a
          barrier and what positive changes you have made in your life since your time in the
          justice system. We use this information to draft your legal documents.
        </div>
      </>
    );
  } else if (psSurveyDone) {
    return (
      <>
        <div>
          Thank you! We have received the information we need to tell the court how your criminal
          record has been a barrier and what positive changes you have made in your life since your
          time in the justice system. We will use this information to draft your legal documents.
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        While we wait for BCI to process your application, we need to gather some information from
        you, so we can prepare your court documents. More specifically, we need to tell the court
        how your criminal record has been a barrier and what positive changes you have made in your
        life since your time in the justice system.
      </div>
      <div className="mt-2">
        We collect this through a simple survey that should take you about 10 minutes to complete.{" "}
        <Link onClick={() => navigate("/statement")}>
          <span className="text-rasapink underline">Please fill it out here.</span>
        </Link>
      </div>
    </>
  );
};

export const getWaitingForBciDescription = (isCurrent, date, bciIneligible) => {
  if (bciIneligible) {
    return (
      "BCI has determined you are ineligible to proceed with" +
      " expunging your convictions at this time either due to an open" +
      " case, out of state/federal cases, fees still owed," +
      " or too many cases on your record. We will evaluate your case" +
      " for next best steps to move towards expungement."
    );
  } else if (isCurrent) {
    return (
      <div>
        <div>
          Your BCI application was submitted on {date}. We are now waiting for BCI to review your
          application and get back to us with the results.
        </div>
        <div className="mt-2">
          Please note <b>it usually takes 2-4 months to hear back from BCI.</b> Rasa has no way to
          speed up this process.
        </div>
      </div>
    );
  }
  return (
    "During this phase, BCI reviews your application and gets back to us with the results." +
    " It usually takes 2-4 months to hear back from BCI. Rasa has no way to speed up this process."
  );
};

export const getDraftingDescription = (isCurrent) => {
  if (isCurrent) {
    return "We have heard from BCI and are currently drafting and filing your legal documents.";
  }
  return "During this phase, Rasa is drafting and filing your legal documents.";
};

export const getProsecutorReviewDescription = (isCurrent, isObjection, filedDate) => {
  let formattedDate;
  if (filedDate) {
    formattedDate = format(parseISO(filedDate), formatShortString);
  }
  if (isObjection) {
    return (
      <div>
        <div>
          We have received an objection to your Petition for Expungement by the prosecutor and/or
          the victim in this matter. The Court will now schedule a hearing.
        </div>
        <div className="mt-2">
          If you would still like to proceed, we will invoice you $500, file a response to the
          objection and will represent you at the hearing. We will be in touch with more detail
          about this case.
        </div>
      </div>
    );
  } else if (isCurrent) {
    return (
      <>
        <div>
          Congratulations! Rasa filed your court paperwork{filedDate && ` on ${formattedDate}`}. We
          are now waiting for the prosecutor to review it. The prosecutor could agree to let your
          expungement move forward or could file an objection.
        </div>
        <div className="mt-2">The prosecutor has 60 days to complete this step.</div>
      </>
    );
  }
  return (
    "During this phase, we wait for the prosecutor to review the court documents drafted and filed by Rasa." +
    " The prosecutor could agree to let your expungement move forward or could file an objection. The prosecutor has 60 days to complete this step."
  );
};

export const getWaitingForCourtDescription = (isCurrent, isHearing, isDenied) => {
  if (isDenied) {
    return (
      "The Court has denied the Petition for Expungement on this matter." +
      " We will evaluate your case for the next best steps to move towards future expungement."
    );
  } else if (isHearing) {
    return (
      "The Court in this matter has set a hearing to hear argument about whether" +
      " this expungement should be granted. If you would still like" +
      " to move forward, we will invoice you $500 and will represent" +
      " you at this hearing. We will be in touch with more detail about this case."
    );
  } else if (isCurrent) {
    return (
      "Good news! Your documents have been filed and we have not received any objections to" +
      " your expungement. We are now waiting for the court to make a final decision."
    );
  }
  return (
    "At this point, your documents have been filed and we have not received any objections to" +
    " your expungement. We wait for the court to make a final decision."
  );
};

export const getFinalizationDescription = (isCurrent) => {
  // todo, do we need a non-successful message here?
  if (isCurrent) {
    return (
      " Congratulations! Your expungement petition has been granted and the court has ordered" +
      " that this case be expunged. We have sent a copy of your expungement order to BCI and we are now" +
      " waiting for BCI to confirm that they have removed this case from your Utah Criminal" +
      " History and notified the FBI."
    );
  }
  return (
    "During this phase, if your expungement was successful, Rasa will send" +
    " a copy of your expungement order to BCI and will wait for BCI to" +
    " confirm that they have removed this case from your Utah Criminal History and notified the FBI."
  );
};

export const getCompleteDescription = (isCurrent) => {
  if (isCurrent) {
    return (
      <div>
        Everything is complete! Watch your <ClioLink /> portal for copies of your petition, court
        order, and BCI confirmation letter.
      </div>
    );
  }
  return (
    "When everything is complete, Rasa will make all your documents" +
    " available for you to download and keep for your records."
  );
};

export const getClientDisengaged = () => {
  return "You have disengaged in legal services for this case.";
};

export const paymentNeededDescription =
  "It looks like we may need a payment from you to continue your legal work.";

export const ineligibleDescription =
  "It has been determined that this case is not eligible for this legal work.";

export const caseWithdrawnDescription = "This case has been withdrawn from our legal services.";

export const getExpungementDescription = (
  status,
  navigate,
  bciSubmittedDate, // db date
  psSurveyDone,
  filedDate,
  // bciIneligible = false,
  // isHearing = "",
  // isDenied = "",
) => {
  switch (status) {
    case ineligible:
      return getWaitingForBciDescription(false, null, true);
    case clientWithdrew:
      return getClientDisengaged();
    case expungementDenied:
      return getWaitingForCourtDescription(false, false, true);
    case caseWithdrawn:
      return caseWithdrawnDescription;
    case waitingForOtherWork:
      return "This expungement has not yet started. This could be due to other legal work being completed first or for a time period to pass.";
    case yourPersonalInfo:
      return getPersonalInfoDescription(true, psSurveyDone, navigate);
    case waitingForBci:
      return getWaitingForBciDescription(true, bciSubmittedDate, false);
    case draftingFiling:
      return getDraftingDescription(true);
    case prosecutorObjection:
      return getProsecutorReviewDescription(true, true, filedDate);
    case prosecutorReview:
      return getProsecutorReviewDescription(true, false, filedDate);
    case waitingForCourt:
      return getWaitingForCourtDescription(true, false, false);
    case finalization:
      return getFinalizationDescription(true);
    case expungementComplete:
      return getCompleteDescription(true);
    case paymentNeededStatus:
      return paymentNeededDescription;
    default:
      return "The status of this case is not known. Please contact Rasa for more information.";
  }
};
