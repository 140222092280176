import { Link } from "react-router-dom";
import {
  caseWithdrawn,
  caseWithdrawnDescription,
  clientWithdrew,
  draftingFiling,
  getClientDisengaged,
  ineligible,
  ineligibleDescription,
  paymentNeededDescription,
  paymentNeededStatus,
  prosecutorObjection,
  prosecutorReview,
  reductionComplete,
  reductionDenied,
  waitingForCourt,
  yourPersonalInfo,
} from "./DescriptionsExpungement";
import { ClioLink } from "../../Clio/ClioPage";
import { format, parseISO } from "date-fns";
import { formatShortString } from "../utilsUT";

export const getReductionPersonalInfoDescription = (psSurveyDone, navigate) => {
  if (psSurveyDone) {
    return (
      <>
        <div>
          Thank you! We have received the information we need to tell the court how your criminal
          record has been a barrier and what has positive changes you have made in your life since
          your time in the justice system. We will use this information to draft your legal
          documents.
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        In order to prepare your court documents, we need to gather some information from you. More
        specifically, we need to tell the court how your criminal record has been a barrier and what
        positive changes you have made in your life since your time in the justice system.
      </div>
      <div className="mt-2">
        We collect this through a simple survey that should take you about 10 minutes to complete.{" "}
        <Link onClick={() => navigate("/statement")}>
          <span className="text-rasapink underline">Please fill it out here.</span>
        </Link>
      </div>
    </>
  );
};

export const getReductionDraftingDescription = (isCurrent) => {
  if (isCurrent) {
    return "We are currently drafting and filing your legal documents.";
  }
  return "During this phase, Rasa is drafting and filing your legal documents.";
};

export const getReductionProsecutorReviewDescription = (isCurrent, isObjection, filedDate) => {
  let formattedDate;
  if (filedDate) {
    formattedDate = format(parseISO(filedDate), formatShortString);
  }
  if (isObjection) {
    return (
      <div>
        <div>
          We have received an objection to your Motion for Reduction by the prosecutor and/or the
          victim in this matter. The Court will now schedule a hearing.
        </div>
        <div className="mt-2">
          If you would still like to proceed, we will invoice you $500, file a response to the
          objection and will represent you at the hearing. We will be in touch with more detail
          about this case.
        </div>
      </div>
    );
  } else if (isCurrent) {
    return (
      <>
        <div>
          Congratulations! Rasa filed your court paperwork{filedDate && ` on ${formattedDate}`}. We
          are now waiting for the prosecutor to review it. The prosecutor could agree to let your
          reduction move forward or could file an objection.
        </div>
        <div className="my-2">The prosecutor has 35 days to respond.</div>
        <div>
          Please note, if you are seeking a two-step reduction, the prosecutor MUST consent to the
          reduction in order to move forward.
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        During this phase, we wait for the prosecutor to review the court documents drafted and
        filed by Rasa. The prosecutor could agree to let your reduction move forward or could file
        an objection.
      </div>
      <div className="my-2">The prosecutor has 35 days to respond.</div>
      <div>
        Please note, if you are seeking a two-step reduction, the prosecutor MUST consent to the
        reduction in order to move forward.
      </div>
    </>
  );
};

export const getReductionWaitingForCourtDescription = (isCurrent, isHearing, isDenied) => {
  if (isDenied) {
    return (
      "The Court has denied the Motion for Reduction on this matter." +
      " We will evaluate your case for the next best steps."
    );
  } else if (isHearing) {
    return (
      "The Court in this matter has set a hearing or we have received an objection" +
      " to hear argument about whether" +
      " this reduction should be granted. If you would still like" +
      " to move forward, we will invoice you $500 and will represent" +
      " you at this hearing. We will be in touch with more detail about this case."
    );
  } else if (isCurrent) {
    return (
      "Good news! Your documents have been filed and we have not received any objections to" +
      " your reduction. We are now waiting for the court to make a final decision."
    );
  }
  return (
    "At this point, your documents have been filed and we have not received any objections to" +
    " your reduction. We wait for the court to make a final decision."
  );
};

export const getReductionCompleteDescription = (isCurrent) => {
  if (isCurrent) {
    return (
      <div>
        <div>
          Everything is complete! Watch your <ClioLink /> portal for copies of your motion and court
          order.
        </div>
        <div className="mt-2">
          If you needed this reduction to be eligible for expungement, reach out to Rasa to discuss
          starting the expungement process.
        </div>
      </div>
    );
  }
  return (
    "When everything is complete, Rasa will make all your documents" +
    " available for you to download and keep for your records."
  );
};

export const getTwoStepObjectionDescription = () => {
  return (
    <div>
      <div>
        We have received an objection to your Motion for Two Step Reduction by the prosecutor and/or
        the victim in this matter.
      </div>
      <div className="mt-2">
        Because this is a two step reduction, we cannot move forward without the prosecutor's
        agreement. We will be in touch about next steps.
      </div>
    </div>
  );
};

export const getReductionDescription = (
  status,
  navigate,
  psSurveyDone,
  isHearing = false,
  isDenied = false,
  filedDate,
) => {
  switch (status) {
    case yourPersonalInfo:
      return getReductionPersonalInfoDescription(psSurveyDone, navigate);
    case draftingFiling:
      return getReductionDraftingDescription(true);
    case prosecutorReview:
      return getReductionProsecutorReviewDescription(true, false, filedDate);
    case prosecutorObjection:
      return getReductionProsecutorReviewDescription(true, true, filedDate);
    case reductionDenied:
      return getTwoStepObjectionDescription();
    case waitingForCourt:
      return getReductionWaitingForCourtDescription(true, isHearing, isDenied);
    case reductionComplete:
      return getReductionCompleteDescription(true);
    case paymentNeededStatus:
      return paymentNeededDescription;
    case clientWithdrew:
      return getClientDisengaged();
    case ineligible:
      return ineligibleDescription;
    case caseWithdrawn:
      return caseWithdrawnDescription;
    default:
      return "The status of this case is not known. Please contact Rasa for more information.";
  }
};
