import { format, parseISO } from "date-fns";
import {
  bciAppPhase,
  formatShortString,
  mapExpungementWorkflowToStatus,
  mapOtherMotionWorkflowToStatus,
  mapReductionWorkflowToStatus,
  needPS,
} from "../utilsUT";
import { EXPUNGEMENT, REDUCTION } from "../constants";
import {
  SEALING,
  MARIJUANA_EXPUNGEMENT,
  SET_ASIDE,
  CERTIFICATE_OF_SECOND_CHANCE,
  RIGHTS_RESTORATION,
  FINGERPRINT_CARD,
} from "./constants";
import { mapWorkflowToStatus } from "./mapWorkflowToStatusAZ";
const compareCaseNumber = (a, b) => a.case_year - b.case_year || a.case_number - b.case_number;

export const setUpAZ = (
  matters,
  state_specific_data,
  setPaymentNeeded,
  showPsNeeded,
  setShowPsNeeded,
  setMattersAZ,
) => {
  const marijuana_expungements = [];
  const sealings = [];
  const set_asides = [];
  const certificates_of_second_chance = [];
  const rights_restorations = [];
  const fingerprint_cards = [];
  const other_motions = [];
  matters.forEach((m) => {
    if (m.legal_action_type === MARIJUANA_EXPUNGEMENT) {
      marijuana_expungements.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else if (m.legal_action_type === SEALING) {
      sealings.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else if (m.legal_action_type === SET_ASIDE) {
      set_asides.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else if (m.legal_action_type === CERTIFICATE_OF_SECOND_CHANCE) {
      certificates_of_second_chance.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else if (m.legal_action_type === RIGHTS_RESTORATION) {
      rights_restorations.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else if (m.legal_action_type === FINGERPRINT_CARD) {
      fingerprint_cards.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    } else {
      other_motions.push(mapWorkflowToStatus(m, showPsNeeded, setShowPsNeeded));
    }
  });
  const obj = {
    marijuana_expungements: marijuana_expungements.sort(compareCaseNumber),
    sealings: sealings.sort(compareCaseNumber),
    set_asides: set_asides.sort(compareCaseNumber),
    certificates_of_second_chance: certificates_of_second_chance.sort(compareCaseNumber),
    rights_restorations: rights_restorations.sort(compareCaseNumber),
    fingerprint_cards: fingerprint_cards.sort(compareCaseNumber),
    other_motions: other_motions.sort(compareCaseNumber),
  };
  setMattersAZ(obj);
};

export const setUpUT = (
  matters,
  state_specific_data,
  setBciSubmitted,
  setPaymentNeeded,
  showPsNeeded,
  showBciDateNeeded,
  setOneExpungementInBciApplicationStage,
  setOneExpungementInPersonalStatementStage,
  setMattersExpungement,
  setMattersReduction,
  setMattersOther,
  setShowBciDateNeeded,
  oneExpungementInBciApplicationStage,
) => {
  let bciDate;
  if ("bci_submitted" in state_specific_data && state_specific_data.bci_submitted) {
    bciDate = format(parseISO(state_specific_data.bci_submitted), formatShortString);
    setBciSubmitted(bciDate);
  }
  const expMatters = [];
  const reduMatters = [];
  const otherMatters = [];
  matters.forEach((m) => {
    if (m.legal_action_type === EXPUNGEMENT) {
      expMatters.push(
        mapExpungementWorkflowToStatus(m, setPaymentNeeded, showPsNeeded, showBciDateNeeded),
      );
      if (m?.workflow_stage?.toLowerCase().includes(bciAppPhase)) {
        setOneExpungementInBciApplicationStage(true);
      } else if (m?.workflow_stage?.toLowerCase().includes(needPS)) {
        setOneExpungementInPersonalStatementStage(true);
      }
    } else if (m.legal_action_type === REDUCTION) {
      reduMatters.push(mapReductionWorkflowToStatus(m, setPaymentNeeded, showPsNeeded));
      if (
        m?.workflow_stage?.toLowerCase().includes("need info") &&
        !m?.workflow_stage?.toLowerCase().includes("pmt")
      ) {
        setOneExpungementInPersonalStatementStage(true);
      }
    } else {
      otherMatters.push(mapOtherMotionWorkflowToStatus(m));
    }
  });
  setMattersExpungement(expMatters.sort(compareCaseNumber));
  setMattersReduction(reduMatters.sort(compareCaseNumber));
  setMattersOther(otherMatters.sort(compareCaseNumber));
  setShowBciDateNeeded(!bciDate && expMatters.length > 0 && oneExpungementInBciApplicationStage);
};
