export const container = "container relative mx-auto p-6";

export const container2 = "container mx-auto mt-10 flex max-w-3xl flex-col px-4";
// "relative mt-2 rounded-md shadow-sm"

export const container3 = "container mx-auto mb-8 mt-2 flex max-w-3xl flex-col px-4";

export const container4 = "container mx-auto mt-10 mb-14 flex max-w-3xl flex-col px-4";

export const buttonContainer = "mb-6 py-6 px-4";

export const buttonContainerCenter = "flex justify-center mb-6 py-6 px-4";

export const buttonFlexContainer = "flex mb-6 py-6 justify-between";

export const errorClass = "mt-4 text-center text-base font-semibold text-red-600";

export const errorUnderInput = "text-sm text-red-600";

export const flexCenter = "flex justify-center";

export const logo = "flex justify-center mt-2";

export const logoBasicLayout = "mt-6 flex justify-center mt-2";

export const header2 = "mb-6 text-center font-serif text-xl";
export const headerLeft = "mx-6 mb-6 font-serif text-xl";

export const questionMain = "mb-2 font-serif text-xl lowercase leading-6";

export const questionMain2 = "font-serif text-2xl lowercase";

export const questionMain3 = "font-serif text-xl lowercase";

export const recordLabel = "mb-1 font-serif text-2xl lowercase";

export const labelMain = "mb-6 font-serif text-2xl lowercase";

export const labelSecondary = "font-sans text-base font-bold";

export const question = "mt-6 font-serif text-base font-semibold lowercase leading-5";
export const question2 = "mt-1 font-serif text-base font-semibold lowercase";

export const textareaClass =
  "block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 " +
  "placeholder:text-gray-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-rasapink sm:text-sm sm:leading-6";

export const textareaClassPink =
  "block w-full rounded-md border-0 py-2 bg-pink-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 " +
  "placeholder:text-gray-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-rasapink sm:text-sm sm:leading-6";

export const menu = "mx-10 flex justify-center";

export const input =
  "block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 " +
  "placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6";

export const inputPink =
  "block w-full rounded-md border-0 py-2 bg-pink-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 " +
  "placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6";

export const inputLabel = "block font-bold leading-6 text-gray-900";

export const inputLabelNoWrap = "block whitespace-nowrap font-bold leading-6 text-gray-900";

export const inputWrapper = "relative rounded-md shadow-sm";

export const inputWrapperNoLabel = "mt-4 relative";

export const inputWrapperFlex = "relative flex rounded-md shadow-sm";

// radio
export const radio =
  "form-radio ml-4 cursor-pointer appearance-none text-rasapink checked:bg-rasapink focus:ring-rasapink";
export const radioWrapper =
  "relative mt-2 flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-500";

export const radioLabel = "block w-full cursor-pointer py-2 px-4 leading-6 text-gray-900";

export const checkboxLabel = "block w-full cursor-pointer p-2 text-base text-gray-900";

// buttons
export const menuButtonClass =
  "bg-white p-4 font-semibold text-gray-900 underline decoration-rasapink hover:font-bold";

export const menuProgressButton =
  "bg-white p-2 font-semibold text-gray-900 underline decoration-rasapink hover:font-bold";

export const menuProgressDiv =
  "bg-white px-2 pt-3 font-semibold text-gray-900 underline decoration-rasapink";

export const menuButtonWrapper = "flex text-left";
export const primaryButton =
  "mb-4 w-full rounded-full bg-rasapink p-4 font-semibold text-white hover:shadow-xl disabled:opacity-50";

export const primaryButtonSmall =
  "mb-4 rounded-full bg-rasapink py-2 px-6 font-semibold text-white hover:shadow-xl disabled:opacity-50";

export const primaryButtonSmall2 =
  "mb-4 w-full rounded-full bg-rasapink py-2 px-6 font-semibold text-white font-semibold hover:shadow-xl disabled:opacity-50";

export const primaryButtonMax =
  "mb-4 rounded-full bg-rasapink py-4 px-6 font-semibold text-white hover:shadow-xl";

export const secondaryButton =
  "mb-4 w-full rounded-full border border-rasapink bg-white p-4 font-semibold text-rasapink hover:shadow-xl";

export const secondaryButtonSmall =
  "mb-4 w-full rounded-full border border-rasapink bg-white py-2 px-6 font-semibold text-rasapink hover:shadow-xl";

export const secondaryButtonNotFull =
  "mb-6 rounded-full border border-rasapink bg-white p-4 font-semibold text-rasapink hover:shadow-xl";

export const tertiaryButtonSmall =
  "mb-4 w-full rounded-full border border-black bg-pink-200 py-2 px-6 text-black font-semibold hover:shadow-xl disabled:opacity-50";

export const tertiaryButton =
  "mb-4 w-full rounded-full border border-black bg-pink-200 py-4 px-2 text-black font-semibold hover:shadow-xl disabled:opacity-50";

export const messageStatus =
  "mt-2 mb-6 rounded-md border-2 border-rasapink bg-pink-100 p-4 text-center font-semibold";

export const messageStatusSmall =
  "mb-6 mx-6 rounded-md border-2 border-rasapink bg-pink-100 p-2 text-center font-semibold";

export const animate = "animate-fade px-2 pb-2";

export const pinkSection = "rounded-md bg-pink-100";
