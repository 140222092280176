import { useState } from "react";
import {
  BCI,
  Complete,
  Court,
  Drafting,
  Final,
  Identification,
  PersonalInfo,
  Prosecutor,
} from "../icons";
import { ShowContent } from "../ShowContent";
import { useNavigate } from "react-router-dom";
import {
  bciApplication,
  draftingFiling,
  expungementComplete,
  finalization,
  getBciApplicationDescription,
  getCompleteDescription,
  getDraftingDescription,
  getFinalizationDescription,
  getPersonalInfoDescription,
  getProsecutorReviewDescription,
  getWaitingForBciDescription,
  getWaitingForCourtDescription,
  prosecutorReview,
  waitingForBci,
  waitingForCourt,
  yourPersonalInfo,
} from "../Descriptions/DescriptionsExpungement";
import { STATIC } from "../ProgressStepper/ProgressStepperExpungement";
export const actionEntity = "-mt-1 mb-1 text-xs font-normal tracking-tighter";
export const LI = "border-b border-grey-500 py-2";
export const row = "flex w-full justify-between";
export const content = "ml-8";

export const ProcessExpungement = ({
  setOpenApplicationSubmitted,
  bciSubmitted,
  psSurveyDone,
  isPersonalStatementNeeded,
  showBciDateNeeded,
}) => {
  const navigate = useNavigate();
  // const { state } = useLocation();
  const [isShowingBciApp, setIsShowingBciApp] = useState(false);
  const [isShowingBci, setIsShowingBci] = useState(false);
  const [isShowingPersonalInfo, setIsPersonalInfo] = useState(false);
  const [isShowingDraft, setIsShowingDraft] = useState(false);
  const [isShowingProsecutor, setIsShowingProsecutor] = useState(false);
  const [isShowingCourt, setIsShowingCourt] = useState(false);
  const [isShowingFinal, setIsShowingFinal] = useState(false);
  const [isShowingComplete, setIsShowingComplete] = useState(false);

  return (
    <>
      <ol className="mt-3">
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Identification stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {bciApplication} <div className={actionEntity}> (you)</div>
              </div>
              <ShowContent isShowing={isShowingBciApp} setIsShowing={setIsShowingBciApp} />
            </div>
          </div>
          {isShowingBciApp && (
            <div className={content}>
              {getBciApplicationDescription(
                bciSubmitted,
                showBciDateNeeded,
                setOpenApplicationSubmitted,
              )}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <PersonalInfo stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {yourPersonalInfo} <div className={actionEntity}> (you)</div>
              </div>
              <ShowContent isShowing={isShowingPersonalInfo} setIsShowing={setIsPersonalInfo} />
            </div>
          </div>
          {isShowingPersonalInfo && (
            <div className={content}>
              {getPersonalInfoDescription(isPersonalStatementNeeded, psSurveyDone, navigate)}
            </div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <BCI stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {waitingForBci} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingBci} setIsShowing={setIsShowingBci} />
            </div>
          </div>
          {isShowingBci && (
            <div className={content}>{getWaitingForBciDescription(false, bciSubmitted, false)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Drafting stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {draftingFiling} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingDraft} setIsShowing={setIsShowingDraft} />
            </div>
          </div>
          {isShowingDraft && <div className={content}>{getDraftingDescription(false)}</div>}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Prosecutor stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {prosecutorReview} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingProsecutor} setIsShowing={setIsShowingProsecutor} />
            </div>
          </div>
          {isShowingProsecutor && (
            <div className={content}>{getProsecutorReviewDescription(false, false, null)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Court stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {waitingForCourt} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingCourt} setIsShowing={setIsShowingCourt} />
            </div>
          </div>
          {isShowingCourt && (
            <div className={content}>{getWaitingForCourtDescription(false, false, false)}</div>
          )}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Final stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {finalization} <div className={actionEntity}> (government)</div>
              </div>
              <ShowContent isShowing={isShowingFinal} setIsShowing={setIsShowingFinal} />
            </div>
          </div>
          {isShowingFinal && <div className={content}>{getFinalizationDescription(false)}</div>}
        </li>
        <li className={LI}>
          <div className="flex">
            <div className="mr-2">
              <Complete stepStatus={STATIC} />
            </div>
            <div className={row}>
              <div className="font-bold leading-4">
                {expungementComplete} <div className={actionEntity}> (rasa)</div>
              </div>
              <ShowContent isShowing={isShowingComplete} setIsShowing={setIsShowingComplete} />
            </div>
          </div>
          {isShowingComplete && <div className={content}>{getCompleteDescription(false)}</div>}
        </li>
      </ol>
    </>
  );
};
