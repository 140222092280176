export const marijuana_expungements = "marijuana_expungements";
export const MARIJUANA_EXPUNGEMENT = "";
export const sealings = "sealings";
export const SEALING = "SEALING";
export const set_asides = "set_asides";
export const SET_ASIDE = "SET_ASIDE";
export const certificates_of_second_chance = "certificates_of_second_chance";
export const CERTIFICATE_OF_SECOND_CHANCE = "CERTIFICATE_OF_SECOND_CHANCE";
export const rights_restorations = "rights_restorations";
export const RIGHTS_RESTORATION = "RIGHTS_RESTORATION";
export const fingerprint_cards = "fingerprint_cards";
export const FINGERPRINT_CARD = "FINGERPRINT_CARD";
export const other_motions = "other_motions";
export const OTHER_MOTION = "OTHER_MOTION";
export const initMattersAZ = {
  marijuana_expungements: [],
  sealings: [],
  set_asides: [],
  certificates_of_second_chance: [],
  rights_restorations: [],
  fingerprint_cards: [],
  other_motions: [],
};
